<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-20 14:42:31
 * @LastEditors: ------
 * @LastEditTime: 2021-09-24 16:10:49
-->
<template>
  <div class="serveProject_container">
    <div class="serveProject_banner">
      <img src="@/assets/serveProject/banner.png" alt="" />
    </div>
    <div class="sticky">
      <van-tabs
        title-active-color="#F58B1C"
        swipeable
        animated
        border
        sticky
        :ellipsis="false"
        color="#F58B1C"
        v-model="num.id"
        @change="change"
      >
        <van-tab v-for="(item, index) in tab" :title="item" :key="index">
          <div class="container">
            <div class="juJiaZhaoGu" v-show="num.id == 0">
              <div class="con_title">
                {{ item }}
              </div>
              <p class="font_pro">
                适用于为社区长者提供全方位24小时住家生活照料服务，即为老管家服务，包括居家长者的吃、穿、住、行、洁、买、和医养护各个方面的全程介护照顾。
              </p>
              <div class="fenLei">
                <div class="jujia">
                  <img src="@/assets/serveProject/juJia.png" alt="" />
                </div>
                <div class="list_con">
                  <div class="div1">
                    <p>
                      慢病照护(糖尿病等)
                    </p>
                    <p class="p2">
                      6000元/月 (起)
                    </p>
                  </div>
                  <div class="div2">
                    <p>
                      生活照护
                    </p>
                    <p class="p2">
                      6000元/月 (起)
                    </p>
                  </div>
                  <div class="div3">
                    <p>
                      渐进性肌无力照护
                    </p>
                    <p class="p2">
                      6000元/月 (起)
                    </p>
                  </div>
                  <div class="div4">
                    <p>
                      康复症照护
                    </p>
                    <p class="p2">
                      6800元/月 (起)
                    </p>
                  </div>
                  <div class="div5">
                    <p>
                      居家术后照护
                    </p>
                    <p class="p2">
                      6800元/月 (起)
                    </p>
                  </div>
                  <div class="div6">
                    <p>
                      认知症照护
                    </p>
                    <p class="p2">
                      6800元/月 (起)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="changHuXian" v-show="num.id == 1">
              <div class="con_title">
                {{ item }}
              </div>
              <div class="shouYiDuiXiang">
                <div class="imgs">
                  <img src="@/assets/serveProject/shouYiDuiXiang.png" alt="" />
                </div>
                <div class="DduiXiangFont">
                  <div class="duiXiang_title">
                    <span class="title_line">受益</span><span>对象</span>
                  </div>
                  <div class="dx_two">
                    <p>
                      <img src="../../assets/serveProject/yuan.png" alt="" />
                      参加本市职工医保的60岁以上的人员
                    </p>
                    <p>
                      <img src="../../assets/serveProject/yuan.png" alt="" />
                      参加本市城乡居民医保的60岁以上的人员
                    </p>
                  </div>
                </div>
              </div>
              <div class="dengJi">
                <div class="duiXiang_title">
                  <span class="title_line">评估</span><span>等级</span>
                </div>
                <div class="dengJi_con">
                  <div class="dj_img">
                    <img src="@/assets/serveProject/dengji1.png" alt="" />
                  </div>
                  <div class="dj_font">
                    <p>二、三级</p>
                    <p>
                      每周上门3次
                    </p>
                    <p>
                      每次一小时
                    </p>
                  </div>
                </div>
                <div class="dengJi_con">
                  <div class="dj_font">
                    <p>四级</p>
                    <p>
                      每周上门5次
                    </p>
                    <p>
                      每次1小时
                    </p>
                  </div>
                  <div class="dj_img">
                    <img src="@/assets/serveProject/dengji2.png" alt="" />
                  </div>
                </div>
                <div class="dengJi_con">
                  <div class="dj_img">
                    <img src="@/assets/serveProject/dengji3.png" alt="" />
                  </div>
                  <div class="dj_font">
                    <p>五、六级</p>
                    <p>
                      每周上门7次
                    </p>
                    <p>
                      每次1小时
                    </p>
                  </div>
                </div>
              </div>
              <div class="serve_con">
                <div class="duiXiang_title">
                  <span class="title_line">服务</span><span>内容</span>
                </div>
                <div class="serve_list">
                  <div class="list_tit">基本生活照料</div>
                  <div class="list_item">
                    <div class="item_box">
                      <div
                        class="items"
                        v-for="(item, index) in lists"
                        :key="index"
                      >
                        <img src="@/assets/serveProject/duiHao.png" alt="" />
                        <span>{{ item }}</span>
                      </div>
                    </div>

                    <div class="item_box">
                      <div
                        class="items"
                        v-for="(item, index) in lists1"
                        :key="index"
                      >
                        <img src="@/assets/serveProject/duiHao.png" alt="" />
                        <span>{{ item }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="serve_list">
                  <div class=" list_tit list_tit1">常用临床护理</div>
                  <div class="list_item list_item1">
                    <div class="item_box">
                      <div
                        class="items"
                        v-for="(item, index) in list2"
                        :key="index"
                      >
                        <img src="@/assets/serveProject/duiHao.png" alt="" />
                        <span>{{ item }}</span>
                      </div>
                    </div>

                    <div class="item_box">
                      <div
                        class="items"
                        v-for="(item, index) in list3"
                        :key="index"
                      >
                        <img src="@/assets/serveProject/duiHao.png" alt="" />
                        <span>{{ item }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="price">
                <div class="duiXiang_title">
                  <span class="title_line">自费</span><span>价格</span>
                </div>
                <div class="zifei_con">
                  <div class="zifei_img">
                    <img src="@/assets/serveProject/zifei.png" alt="" />
                  </div>
                  <div class="zifei_font">
                    <div class="zifei_right">
                      <img src="@/assets/serveProject/yuan.png" alt="" />
                      <span>
                        基本生活照料：护理员上门6.5/小时（自付）
                      </span>
                    </div>
                    <div class="zifei_right">
                      <img src="@/assets/serveProject/yuan.png" alt="" />
                      <span>
                        常用临床护理：护士上门8元/小时（自付）
                      </span>
                    </div>
                    <div class="zifei_right">
                      <img src="@/assets/serveProject/yuan.png" alt="" />
                      <span>
                        市老红军、离休干部、一至六级革命伤残军人，服务费用全免
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="liuChen">
                <div class="duiXiang_title">
                  <span class="title_line">申请</span><span>流程</span>
                </div>
                <div class="liucheng_bg">
                  <div class="lc_font">
                    <p>
                      申请和受理
                    </p>
                    <p class="p2">
                      <span class="dian"></span>
                      <span class="dian_item">递交申请</span>
                    </p>
                    <p class="p2">
                      <span class="dian"></span>
                      <span class="dian_item">预约评估</span>
                    </p>
                  </div>
                  <div class="lc_font lc_font2">
                    <p>
                      服务
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 照护2-3级每周上门3次 </span>
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 照护4级每周上门5次</span>
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 照护5-6级每周上门7次</span>
                    </p>
                  </div>
                  <div class="lc_font lc_font3">
                    <p>
                      照护等级评估
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 上门评估 </span>
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 确定等级</span>
                    </p>
                  </div>
                  <div class="lc_font lc_font4">
                    <p>
                      结算
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 社区居家照护 </span>
                    </p>
                    <p class="p2">
                      <span class="dian"> </span>
                      <span class="dian_item"> 长护险支付90%, 个人支付10%</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="yiYuan" v-show="num.id == 2">
              <div class="con_title">
                {{ item }}
              </div>
              <div class="fuWuNeiRong">
                <div class="duiXiang_title">
                  <span class="title_line">服务</span><span>内容</span>
                </div>
                <div class="neiRong1">
                  综合评估病人的服务需求，与专业医生一起共同制定术后或疾病护理方案，与患者和家人共同研究每一项护理任务，帮助患者在出院后继续得到专业、全面的护理，促进疾病康复以避免因护理不当导致重复入院。
                </div>
                <div class="nr_img nr_img1">
                  <div class="nr_title">
                    <div class="tit_top">
                      健康与安全管理
                      <p class="line_p"></p>
                      <div class="tit_main">
                        <div class="tit_left">
                          <p>环境评估</p>
                          <p>疾病管理</p>
                          <p>运动管理</p>
                        </div>
                        <div class="tit_right">
                          <p>健康评估</p>
                          <p>营养管理</p>
                          <p>护理团队管理</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nr_img nr_img2">
                  <div class="nr_title">
                    <div class="tit_top">
                      医疗护理
                      <p class="line_p"></p>
                      <div class="tit_main">
                        <div class="tit_left">
                          <p>药物管理</p>
                          <p>血标本采集</p>
                          <p>肌肉注射</p>
                          <p>皮内注射</p>
                        </div>
                        <div class="tit_right">
                          <p>灌肠</p>
                          <p>导尿</p>
                          <p>皮下注射</p>
                          <p>血糖监测</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nr_img nr_img3">
                  <div class="nr_title">
                    <div class="tit_top">
                      生活功能训练
                      <p class="line_p"></p>
                      <div class="tit_main">
                        <div class="tit_left">
                          <p>安全进食指导</p>
                          <p>行走辅具使用指导</p>
                          <p>生活自立支持训练</p>
                          <p>压疮护理</p>
                          <p>鼻饲护理</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nr_img nr_img4">
                  <div class="nr_title">
                    <div class="tit_top">
                      专科护理
                      <p class="line_p"></p>
                      <div class="tit_main">
                        <div class="tit_left">
                          <p>造口护理</p>
                          <p>物理降温</p>
                          <p>PICC导管维护</p>
                        </div>
                        <div class="tit_right">
                          <p>压疮护理</p>
                          <p>鼻饲护理</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nr_img nr_img5"></div>
                <div class="nr_img nr_img6">
                  <div class="nr_title">
                    <div class="tit_top">
                      医院陪护
                      <p class="line_p"></p>
                      <div class="tit_main">
                        <div class="tit_left">
                          <p>
                            复诊配药
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </p>
                          <p>
                            护理反馈
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="youShi">
                <div class="duiXiang_title">
                  <span class="title_line">服务</span><span>优势</span>
                </div>
                <div class="youshi_box">
                  <div class="youshi1">
                    <div class="num">01</div>
                    <p>
                      上门护士均为护理大专院校毕业，具有全科系统护理应用知识，熟练的操作技能
                    </p>
                  </div>
                  <div class="youshi2">
                    <div class="num">02</div>
                    <p>
                      患者可以在任何时间联系到家庭护士，随时得到专业指导
                    </p>
                  </div>
                  <div class="youshi3">
                    <div class="num">03</div>
                    <p>
                      上门一对一服务，根据医护需求，提供个性化评估和医护计划
                    </p>
                  </div>
                  <div class="youshi4">
                    <div class="num">04</div>
                    <p>
                      对患者定期上门寻访，及时调整医护方案
                    </p>
                  </div>
                </div>
              </div>
              <div class="jiejue">
                <div class="duiXiang_title">
                  <span class="title_line">解决</span><span>方案</span>
                </div>
                <div class="jiejue_img">
                  <p class="p1">
                    服务咨询与申请
                  </p>
                  <p class="p2">评估医护需求</p>
                  <p class="p3">量身制定照护计划</p>
                  <p class="p4">选派合适的护士</p>
                  <p class="p5">服务监督与反馈</p>
                </div>
              </div>
            </div>
            <div class="laoNianCan" v-show="num.id == 3">
              <div class="con_title">
                {{ item }}
              </div>
              <div class="laoNianCan_font">
                每餐荤素搭配、一周不重样儿，饭菜照顾老年人的口味，做得清淡、软和，为老人们提供“量身定制”的营养餐。
              </div>
              <div class="food">
                <div class="food_left">
                  <img src="../../assets/serveProject/food1.png" alt="" />
                </div>
                <div class="food_right">
                  <img src="../../assets/serveProject/food2.png" alt="" />
                </div>
                <div class="food_left">
                  <img src="../../assets/serveProject/food3.png" alt="" />
                </div>
                <div class="food_right">
                  <img src="../../assets/serveProject/food4.png" alt="" />
                </div>
              </div>
            </div>
            <div class="shiLao" v-show="num.id == 4">
              <div class="con_title">
                {{ item }}
              </div>
              <div class="duiXiang_title">
                <span class="title_line">适老</span
                ><span>化改造包括哪些内容？</span>
              </div>
              <div class="shilao_con">
                <p>
                  为社区居家半失能和失能长者全方位解决适老无障碍设施改造，提供智能化养老解决方案及智能设备租赁服务，通过一些人性化的设施设备实现长者生活便捷化、安全化、智能化。
                </p>
                <div class="shilao_img1">
                  <img src="../../assets/serveProject/gaizao1.png" alt="" />
                </div>
                <p>
                  建筑硬件改造具体可分为：地面、出入口、通道的无障碍改造；扶手及抓杆安装；地面防滑处理等。
                </p>
                <div class="shilao_img1 " style="text-align:right">
                  <img src="../../assets/serveProject/gaizao2.png" alt="" />
                </div>
                <p>
                  家具家装改造具体可分为：室内家具、装饰的棱角防撞设计；推拉门改装；马桶增高设计；水龙头扳手式改造等等。
                </p>
                <div class="shilao_img1 ">
                  <img src="../../assets/serveProject/gaizao3.png" alt="" />
                </div>
                <p>
                  辅具可分为：轮椅、助行器、浴凳等。
                </p>
                <div class="shilao_img1 " style="text-align:right">
                  <img src="../../assets/serveProject/gaizao4.png" alt="" />
                </div>
                <p>
                  智能化用具可分为：防走失手环、紧急呼叫设施、远程断电装置等。
                </p>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",

  data() {
    return {
      tab: ["居家照顾", "长护险服务", "医院陪护", "老年餐定制", "适老化改造"],
      num: {
        id: this.$store.state.serveProject, // 首次从vuex中获取
      },
      lists: [
        "面部清洁和梳理",
        "协助进食/水",
        "床上使用便器",
        "协助翻身叩背排痰",
        "压疮预防护理",
        "口腔清洁",
        "人工取便术",
        "协助床上移动",
        "留置尿管护理",
        "温水擦浴",
        "失禁护理",
        "药物管理",
        "生活自理能力训练",
      ],
      lists1: [
        "指/趾甲护理",
        "进食/水",
        "床上使用便器",
        "协助翻身叩背排痰",
        "压疮预防护理",
        "口腔清洁",
        "人工取便术",
        "协助床上移动",
        "留置尿管护理",
        "温水擦浴",
        "失禁护理",
        "药物管理",
        "生活自理能力训练",
      ],
      list2: [
        "开塞露/直肠栓剂给药",
        "生命体征监测",
        "血糖监测",
        "皮下注射",
        "吸氧",
        "压疮伤口换药",
        "造口护理",
        "经外周静脉置入中心静脉导管(PICC)维护",
      ],
      list3: [
        "药物喂服",
        "灌肠",
        "静脉血标本采集",
        "物理降温",
        "导尿（女性)",
        "肌肉注射",
        "鼻饲",
        "生活自理能力训练",
      ],
    };
  },
  computed: {
    listData() {
      return this.$store.state.serveProject;
    },
  },
  created() {},
  methods: {
    change(e) {
      this.num.id = e;
      this.$store.commit("setserveProject", e); // 存放在状态管理
    },
  },
  watch: {
    listData(newVal) {
      // 监听vuex 数据变化 ， 如果改变就重新赋值
      this.num.id = newVal;
    },
  },
};
</script>

<style scoped lang="less">
.serveProject_container {
  .serveProject_banner {
    img {
      width: 100%;
    }
  }
  .container {
    .con_title {
      text-align: center;
      color: #f58b1c;
      font-weight: bold;
      font-size: 22px;
      padding: 30px 0 26px 0;
    }
    .duiXiang_title {
      font-size: 18px;
      display: flex;
      font-weight: 500;
      .title_line {
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          padding-bottom: 4px;
          border-bottom: 3px solid #f59903;
        }
      }
    }
    .juJiaZhaoGu {
      padding: 0 16px;
      .font_pro {
        font-size: 16px;
        color: #333333;
        line-height: 26px;
      }
      .fenLei {
        display: flex;
        .jujia {
          margin: 30px 0;
          // overflow: hidden;
          img {
            height: 230px;
            width: 260px;
            margin-left: -16px;
          }
        }
        .list_con {
          font-size: 12px;
          color: #333;
          line-height: 18px;
          .p2 {
            color: #ff9500;
          }
          .div1 {
            margin-left: -20px;
            margin-top: 18px;
          }
          .div2 {
            margin-top: 6px;
            margin-left: 4px;
          }
          .div3 {
            margin-top: 8px;
            margin-left: -10px;
          }
          .div4 {
            margin-top: 8px;
            margin-left: 8px;
          }
          .div5 {
            margin-top: 4px;
          }
          .div6 {
            margin-top: 8px;
          }
        }
      }
    }
    .changHuXian {
      color: #333333;
      font-size: 14px;

      .shouYiDuiXiang {
        padding: 0 16px;
        display: flex;
        .imgs img {
          width: 134px;
          height: 142px;
        }
        .DduiXiangFont {
          padding-left: 10px;
          line-height: 22px;
          .dx_two {
            padding-top: 18px;
            p img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .dengJi {
        background: #f7faff;
        padding-top: 30px;
        .duiXiang_title {
          padding: 0 16px;
        }
        .dengJi_con {
          padding-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .dj_img {
            img {
              width: 208px;
              height: 135px;
            }
          }
          .dj_font {
            line-height: 1.8;
            // margin-left: 26px;
            p:nth-child(1) {
              color: #f58b1c;
            }
          }
        }
      }
      .serve_con {
        .duiXiang_title {
          padding: 40px 0 30px 16px;
        }
        .serve_list {
          .list_tit {
            width: 100%;
            background: #fcf3ed;
            height: 48px;
            text-align: center;
            line-height: 48px;
            font-size: 16px;
            font-weight: bold;
            color: #f59903;
          }
          .list_tit1 {
            background: #e4edfc;
          }
          .list_item {
            display: flex;
            // justify-content: space-around;
            background: #fffbf7;
            width: 100%;
            font-size: 16px;
            .item_box {
              width: 50%;
              padding: 20px 12px;
              .items {
                line-height: 36px;
                display: flex;
                img {
                  width: 24px;
                  height: 20px;
                  padding-right: 4px;
                  margin-top: 6px;
                  flex: 1;
                }
                span {
                  flex: 11;
                }
              }
            }
          }
          .list_item1 {
            background: #f7faff;
          }
        }
      }
      .price {
        margin: 30px 0;
        padding: 0 16px;
        .zifei_con {
          display: flex;
          margin-top: 30px;
          .zifei_img {
            img {
              width: 138px;
              height: 142px;
            }
          }
          .zifei_font {
            .zifei_right {
              display: flex;
              font-size: 14px;
              margin-left: 6px;
              img {
                height: 18px;
                width: 18px;
                flex: 1;
                margin-top: 4px;
              }
              span {
                flex: 11;
                line-height: 26px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .liuChen {
        background: #f7faff;
        padding: 30px 16px;
        .liucheng_bg {
          margin-top: 70px;
          background: url("../../assets/serveProject/liucheng.png") no-repeat;
          background-size: contain;
          width: 100%;
          height: 240px;
          .lc_font {
            position: relative;
            line-height: 1.5;
            p:nth-child(1) {
              font-size: 14px;
              font-weight: 600;
              color: #f58b1c;
            }
            .p2 {
              display: flex;
              align-items: center;
              width: 100px;
              .dian {
                display: inline-block;
                height: 8px;
                width: 8px;
                background-color: #ff950077;
                border-radius: 50%;
                flex: 1;
                margin-right: 4px;
              }
              .dian_item {
                flex: 11;
              }
            }
          }
          .lc_font2 {
            right: -160px;
            top: -130px;
            .dian {
              margin-top: -20px;
            }
          }
          .lc_font3 {
            right: -80px;
            top: -100px;
          }
          .lc_font4 {
            right: -240px;
            top: -162px;
          }
        }
      }
    }
    .yiYuan {
      .duiXiang_title {
        font-size: 18px;
        display: flex;
        font-weight: 500;
        .title_line {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            padding-bottom: 4px;
            border-bottom: 3px solid #f59903;
          }
        }
      }
      .fuWuNeiRong {
        padding: 6px 16px;
        .neiRong1 {
          line-height: 26px;
          color: #333333;
          font-size: 16px;
          padding: 20px 0;
        }
        .nr_img {
          display: inline-block;
          width: 171px;
          height: 171px;
          margin-bottom: -3px;
          position: relative;
          .nr_title {
            position: absolute;
            text-align: center;
            width: 100%;
            color: #ffffff;
            font-weight: bold;
            font-size: 14px;
            margin-top: 16px;
            .tit_top {
              .line_p {
                width: 140px;
                margin: auto;
                margin-top: 10px;
                border-bottom: 2px dashed #fff;
              }
              .tit_main {
                display: flex;
                justify-content: space-around;
                text-align: left;
                margin-top: 16px;
                p {
                  line-height: 20px;
                  &::before {
                    content: "";
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    background-color: #fff;
                    border-radius: 50%;
                    margin-right: 2px;
                  }
                }
              }
            }
          }
        }
        .nr_img1 {
          background: url("../../assets/serveProject/reirong1.png") no-repeat;
          background-size: contain;
        }
        .nr_img2,
        .nr_img3,
        .nr_img6 {
          background: url("../../assets/serveProject/reirong2.png") no-repeat;
          background-size: contain;
          .nr_title {
            color: #ff9500;
            .tit_top {
              .line_p {
                border-bottom: 2px dashed #ff9500;
              }
              .tit_main {
                p {
                  &::before {
                    background-color: #ff9500;
                  }
                }
              }
            }
          }
        }
        .nr_img3 {
          background: url("../../assets/serveProject/reirong3.png") no-repeat;
          background-size: contain;
        }
        .nr_img4 {
          background: url("../../assets/serveProject/reirong4.png") no-repeat;
          background-size: contain;
        }
        .nr_img5 {
          background: url("../../assets/serveProject/reirong5.png") no-repeat;
          background-size: contain;
        }
        .nr_img6 {
          background: url("../../assets/serveProject/reirong6.png") no-repeat;
          background-size: contain;
        }
      }
      .youShi {
        background: #f7faff;
        padding: 30px 16px;
        .youshi_box {
          margin-top: 30px;
          background: url("../../assets/serveProject/youshi.png") no-repeat;
          background-size: contain;
          width: 100%;
          height: 400px;
          position: relative;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          .num {
            color: #f58b1c;
            font-size: 16px;
          }
          .youshi1 {
            position: absolute;
            width: 124px;
            top: 10px;
          }
          .youshi2 {
            position: absolute;
            width: 124px;
            top: 120px;
            left: 220px;
          }
          .youshi3 {
            position: absolute;
            width: 124px;
            top: 210px;
          }
          .youshi4 {
            position: absolute;
            width: 124px;
            top: 320px;
            right: 0px;
          }
        }
      }
      .jiejue {
        margin-top: 30px;
        .duiXiang_title {
          padding-left: 16px;
        }
        .jiejue_img {
          background: url("../../assets/serveProject/fangan.png") no-repeat;
          background-size: contain;
          width: 100%;
          height: 200px;
          position: relative;
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          margin-top: 20px;
          p {
            width: 50px;
          }
          .p1 {
            position: absolute;
            bottom: 54px;
            left: 30px;
          }
          .p2 {
            position: absolute;
            bottom: 114px;
            left: 100px;
          }
          .p3 {
            position: absolute;
            bottom: 54px;
            left: 164px;
          }
          .p4 {
            position: absolute;
            bottom: 114px;
            left: 234px;
          }
          .p5 {
            position: absolute;
            bottom: 54px;
            right: 24px;
          }
        }
      }
    }
    .laoNianCan {
      .laoNianCan_font {
        padding: 0 16px;
        color: #333333;
        line-height: 26px;
        font-size: 16px;
      }
      .food {
        margin: 20px 0;
        .food_left {
          padding-left: 16px;
        }
        .food_right {
          padding-right: 16px;
        }
        img {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    .shiLao {
      padding: 0 16px 20px 16px;
      .shilao_con {
        color: #333333;
        line-height: 26px;
        font-size: 16px;
        p {
          margin-top: 18px;
        }
        .shilao_img1 {
          img {
            width: 272px;
            height: 140px;
            margin-top: 18px;
          }
        }
      }
    }
  }

  /deep/ .van-tab {
    font-size: 16px;
  }
  /deep/ .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0 0 1px 0;
    border-color: var(--color666);
  }
  /deep/ .van-sticky--fixed {
    top: 56px;
  }
}
</style>
